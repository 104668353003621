.flag-items {
    width: 100%;
    height: 100%;
    min-height: 349px;
    background-image: url(./assets/flags-bg.svg);
    margin: 1.5rem 0;
    padding: 1rem;
    padding-left: 75px;
    background-repeat: no-repeat;
    background-size: cover;
    filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, .5));
}

.flag-items img {
    width: 100%;
    height: auto;
    max-height: 85px;
    max-width: 85px;
    margin-left: auto;
    margin-right: auto;
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, .5));
}

@media (min-width: 768px) {
    .flag-items .col-md-6-3 {
        flex: 0 0 auto;
        width: 12.5%;
    }
}

@media (max-width: 576px) {
    .flag-items {
        background-position-x: -80px;
        padding-left: 110px
    }
}